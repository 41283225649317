import React, { useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import './scss/style.scss';

import LOGIN from 'src/queries/loginMutation';
import { useUserContext } from 'src/hooks/useUserContext';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/pages/resetPassowrd/ResetPassword'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

function App() {
  const { token, actions } = useUserContext();
  const [handleLogin, {
    data: loginData,
    error: loginError,
    loading: loginLoading,
  }] = useMutation(LOGIN);

  useEffect(() => {
    const token = R.path(['tokenAuth', 'token'], loginData);
    const user = R.path(['tokenAuth', 'user'], loginData);
    const role = R.path(['groups', 'edges', 0, 'node', 'name'], user);

    if (token && user && role !== 'guest') {
      const { setToken, setUser } = actions;

      setToken(token);
      setUser(user);
    }
  }, [loginData]);

  const getError = () => {
    return loginError || R.path(['tokenAuth', 'errors'], loginData) || R.path(['tokenAuth', 'user', 'groups', 'edges', 0, 'node', 'name'], loginData) === 'guest';
  }

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        {
          token
            ? (
              <Switch>
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
              </Switch>
            )
            : (
              <Switch>
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/" exact>
                  <Login
                    onLogin={handleLogin}
                    error={getError()}
                    isLoading={loginLoading}
                  />
                </Route>
              </Switch>
            )
        }
      </React.Suspense>
    </HashRouter>
  );
}

export default App;

import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import * as R from 'ramda';

const UserContext = createContext({});

const UserContextProvider = props => {
  const { children } = props;
  const [userState, setUserState] = useState();
  const [tokenState, setTokenState] = useState();

  useEffect(() => {
    setTokenState(localStorage.getItem('token'));
    setUserState(JSON.parse(localStorage.getItem('user')));
  }, []);

  const setUser = (user) => {

    const userState = {
      ...user,
      role: R.pathOr(null, ['groups', 'edges', 0, 'node', 'name'], user)
    };

    setUserState(userState);

    localStorage.setItem('user', JSON.stringify(userState));
  }

  const setToken = (token) => {
    localStorage.setItem('token', token);
    setTokenState(token);
  }

  const clear = () => {
    setToken();
    setUser();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  const actions = useMemo(
    () => ({
      setUser,
      setToken,
      clear,
    }), [
      setUser,
      setToken,
      clear,
    ]
  );

  const contextValue = useMemo(() => ({
    user: userState,
    token: tokenState,
    actions,
  }), [
    userState,
    tokenState,
    actions,
  ]);

  return (
      <UserContext.Provider value={contextValue}>
        {children}
      </UserContext.Provider>
  );
};

export default UserContextProvider;

export const useUserContext = () => useContext(UserContext);

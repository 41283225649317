import gql from 'graphql-tag'

export default gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      success
      user {
        id
        pk
        username
        email
        phone
        isStaff
        isActive
        firstName
        lastName
        isActive
        verified
        groups {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      errors
    }
  }
`

export const VERIFY_TOKEN = gql`
  mutation verifyToken($token: String!) {
    verifyToken(token: $token) {
      success
    }
  }
`
